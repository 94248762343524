<template>
  <div id="fee-templates" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-tabs v-model="activeTab">
        <b-tab-item
          v-for="item in schoolSessionTemplates"
          :key="item.sessionTime"
          :label="item.sessionTime"
        >
          <b-table :data="item.templates" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
              >{{ item.templates.indexOf(props.row) + 1 }}</b-table-column
            >

            <b-table-column label="Class Group" v-slot="props">{{
              props.row.classGroupName
            }}</b-table-column>

            <b-table-column label="Session Time" v-slot="props">{{
              props.row.sessionTime
            }}</b-table-column>

            <b-table-column label="Amount" v-slot="props">{{
              `₦ ${props.row.amount}`
            }}</b-table-column>

            <b-table-column label="Actions" width="160" v-slot="props">
              <action-buttons
                position="is-left"
                :show-update="false"
                :show-remove="false"
                @initialize-info="initializeInfo(props.row)"
              />
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import CLASS_GROUPS from '../../../../graphql/class_group/ClassGroups.gql'
import { fetchTerm } from '@/assets/js/app_info.js'

export default {
  name: 'FeeTemplates',
  data() {
    return {
      pageTitle: 'Fee Templates',
      schoolId: null,
      classGroups: [],
      feeTemplates: [],
      activeTab: 0,
      id: null,
      context: 'remove',
      sessionTimes: ['First Term', 'Second Term', 'Third Term'],
      termId: null,
      sessionTime: null,
    }
  },
  apollo: {
    classGroups: {
      query: CLASS_GROUPS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  computed: {
    schoolSessionTemplates() {
      return this.fetchSchoolSessionTemplates()
    },
  },
  watch: {
    sessionTime(value) {
      this.activeTab = this.sessionTimes.indexOf(value)
    },
  },
  methods: {
    initializeInfo(fee_template) {
      this.$store.commit('setRecord', {
        classGroupId: fee_template.classGroupId,
        classGroupName: fee_template.classGroupName,
        sessionTime: fee_template.sessionTime,
      })
      this.$router.push(
        `/school/${this.schoolId}/fee_template_info/${fee_template.id}`
      )
    },
    sessionFeeTemplateDetail(sessionTime) {
      let templates = this.feeTemplates.filter(
        (item) => item.sessionTime === sessionTime
      )

      const groupedFeeTemplates = []

      this.classGroups.forEach((classGroup) => {
        let template = templates.filter(
          (template) => template.classGroup.name === classGroup.name
        )

        let amount = template
          .map((item) => (item.feeItem ? item.feeItem.amount : 0))
          .reduce((a, b) => {
            return a + b
          }, 0)

        let id = template[0] ? template[0].id : 'new'

        groupedFeeTemplates.push({
          id: id,
          classGroupId: classGroup.id,
          classGroupName: classGroup.name,
          sessionTime: sessionTime,
          amount: amount,
        })
      })

      return groupedFeeTemplates
    },
    fetchSchoolSessionTemplates() {
      const templates = []
      this.sessionTimes.forEach((sessionTime) => {
        templates.push({
          sessionTime: sessionTime,
          templates: this.sessionFeeTemplateDetail(sessionTime),
        })
      })

      return templates
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.schoolId}/fee_management`,
      },
    ])

    fetchTerm().then((term) => {
      this.termId = parseInt(term.id)
      this.sessionTime = term.session_time
    })

    this.$apollo.addSmartQuery('feeTemplates', {
      query: gql`
        query FeeTemplatesQuery($schoolId: ID!) {
          feeTemplates(schoolId: $schoolId) {
            id
            classGroup {
              id
              name
            }
            feeItem {
              id
              amount
            }
            sessionTime
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.feeTemplates.refetch()
  },
}
</script>
